<template>
  <div style="width: 100%">
    <div
      class="row m-0"
      v-if="user.is_store || user.is_salesman"
      style="width: 100%"
    >
      <div class="text-center mb-5 pl-5" v-if="store" style="width: 100%">
        <img :src="store.logo" style="width: 200px" />
      </div>
    </div>
    <div
      class="row m-0"
      v-if="user.is_store || user.is_salesman"
      style="width: 100%"
    >
      <DashboardNumbers />
    </div>
    <div
      class="row m-0"
      v-if="user.is_lab || user.is_admin"
      style="width: 100%"
    >
      <LaboratoryDashboard style="width: 100%" />
    </div>
  </div>
</template>

<script>
import DashboardNumbers from '@/components/DashboardNumbers.vue'
import LaboratoryDashboard from '@/components/Laboratory/Dashboard/LaboratoryDashboard.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Dashboard',
  components: {
    DashboardNumbers,
    LaboratoryDashboard
  },
  computed: {
    ...mapGetters(['store']),

    user() {
      return this.$store.state.auth.user
    }
  }
}
</script>
